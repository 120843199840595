<template>
  <div class="person_page">
    <div class="person_left">
      <div class="left_box">
        <!-- <div class="imgBox">
          <img src="http://placehold.it/80x80/333/ccc.png" alt="">
        </div> -->
        <div class="spanBox">
          <span>登录用户名</span>
          <span>{{ form.userName }}</span>
        </div>
      </div>
    </div>
    <div class="person_right">
      <div class="itemBox">
        <div class="item" v-if="stateVuex.insider">
          <i style="margin-top:-21px" class="iconfont icon-mima"></i>
          <div class="item_left">
            <span>登录密码</span>
            <span>安全性高的密码可以使账号更安全，建议您定期更换密码</span>
          </div>
          <div class="item_right">
            <span>*********</span>
            <div class="shu"></div>
            <span class="clickSpan" @click="FnalterPwd">修改</span>
          </div>
        </div>
        <div class="item">
          <i style="margin-top:-21px" class="iconfont icon-yonghuxinxi_shoujihao"></i>
          <div class="item_left">
            <span>手机号</span>
            <span>若原手机号已无法接收验证码，请联系商务修改</span>
          </div>
          <div class="item_right">
            <span>{{ tel }}</span>
            <div class="shu"></div>
            <span class="clickSpan" @click="FnalterPhone">修改</span>
          </div>
        </div>
         <div class="item">
          <i style="margin-top:-21px" class="iconfont icon-yonghuxinxi_shijian"></i>
          <div class="item_left">
            <span>账号有效期</span>
            <span>如需延长时间，请联系客服</span>
          </div>
          <div class="item_right">
            <span class="date">{{time}}到期</span>
          </div>
        </div>
        <div class="item_bottom">
          <!-- 图标 -->
          <i style="margin-right:9px" class="iconfont icon-tishi"></i>
          <span> 账号权限只有主账号可以修改，子账号不能自行修改，如需修改请联系主账号人员</span>
        </div>
      </div>
    </div>
      <dialog-pwd v-model="state.dialogVisiblePwd"></dialog-pwd>
      <dialog-phone v-model="state.dialogVisiblePhone"></dialog-phone>
  </div>
</template>

<script>
import { ref, computed, reactive, watch } from 'vue'
import dialogPwd from './alterpwd'
import dialogPhone from './alterphone'
import { useStore } from 'vuex'
export default {
  components: {
    dialogPwd,
    dialogPhone
  },
  setup () {
    // vueX
    const store = useStore()
    const stateVuex = store.state
    const form = reactive({
      userName: stateVuex.username,
      password: ''
    })
    const spanNum = ref(12)
    const originalTel = ref(stateVuex.phone)
    const time = ref(stateVuex.validityTime)
    watch(() => stateVuex.phone, () => {
      originalTel.value = stateVuex.phone
    })
    const state = reactive({
      period: '2021年10月31日 到期',
      dialogVisiblePwd: false,
      dialogVisiblePhone: false
    })
    // 格式化手机号，展示****
    const tel = computed(() => {
      return (
        originalTel.value.substring(0, 3) +
        '****' +
        originalTel.value.substring(7)
      )
    })
    // 修改密码点击事件
    const FnalterPwd = () => {
      state.dialogVisiblePwd = true
    }
    // 修改手机号点击事件
    const FnalterPhone = () => {
      state.dialogVisiblePhone = true
    }
    return { stateVuex, spanNum, originalTel, tel, time, state, FnalterPwd, form, FnalterPhone }
  }
}
</script>
<style scoped lang='scss'>
.person_page{
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 480px;
  border-radius: 4px;
  position: relative;
  .person_left{
    width: 300px;
    padding: 24px 0 0 44px;
    .left_box{
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .imgBox{
        width: 80px;
        height: 80px;
        background: rgb(218, 216, 216);
        border-radius: 8px;
        img{
          width: 80px;
          height: 80px;
          border-radius: 8px;
        }
      }
      .spanBox{
        padding-top: 20px;
        text-align: left;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        span:nth-of-type(1){
          font-size: 14px;
          color: #84868C;
          display: block;
          margin-bottom: 4px;
        }
        span:nth-of-type(2){
          font-size: 14px;
          color: #242426;
          display: block;
        }
      }
    }
  }
  .person_right{
    height: 100%;
    box-sizing: border-box;
    border-left: 1px solid #E9ECF2;
    flex:1;
    padding: 24px;
    .itemBox{
      width: 100%;
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 90px;
        box-sizing: border-box;
        border-bottom: 1px solid #E9ECF2;
        .item_left{
          height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-left: 12px;
          span:nth-of-type(1){
            font-size: 14px;
            color: #242426;
            margin-bottom: 6px;
          }
          span:nth-of-type(2){
            font-size: 12px;
            color: #84868C;
          }
        }
        .item_right{
          display: flex;
          justify-content: space-between;
          .clickSpan{
            cursor: pointer;
          }
          .date{
            font-size: 14px;
            color: #242426;
            white-space: nowrap;
          }
          span:nth-of-type(1){
            font-size: 14px;
            color: #242426;
          }
          span:nth-of-type(2){
            font-size: 14px;
            color: #435B8E;
          }
          .shu{
            width: 1px;
            height: 16px;
            margin-left: 12px;
            margin-right: 12px;
            background:#E9ECF2 ;
          }
        }
      }
      .item:nth-of-type(3){
        border: 0;
      }
      .item_bottom{
        padding-left: 9px;
        width: 595px;
        height: 36px;
        background: #F7F8FA;
        border-radius: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        bottom: 24px;
        right: 24px;
        .icon-tishi {
          color: #84868c;
        }
        span{
          font-size: 12px;
          color: #84868C;
        }
      }
    }
  }
}
</style>
